.rating {
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 20px 15px;

    &__title {
        position: relative;

        &::after {
            content: '';
            width: 90%;
            height: 1px;
            position: absolute;
            background-color: $drawerGrey;
            left: 5%;
            bottom: -20px;
        }

        h2 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(20px);
            color: $black;
            margin: 0 auto;
            max-width: 180px;
            text-align: center;
            position: relative;
            padding-left: 20px;

            &::after {
                content: '';
                background: url('../img/trophy.svg') no-repeat;
                background-size: 20px 20px;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }

    &__list {
        margin-top: 40px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-left: 18%;
        }

        li {
            margin: 12px 0;
        }

        a {
            text-decoration: none;
            color: $black;
            position: relative;

            &::after {
                content: '';
                background: url('../img/arrow.svg') no-repeat;
                background-size: 8px 8px;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 55%;
                transform: translateY(-50%);
                right: -15px;
            }

            &:hover {
                color: $violet;
                text-decoration: underline;
            }
        }

        &__item {
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -30px;
            }

            &--first {
                &::before {
                    background: url('../img/first.svg') no-repeat;
                    background-size: 20px 20px;
                }
            }

            &--second {
                &::before {
                    background: url('../img/second.svg') no-repeat;
                    background-size: 20px 20px;
                }
            }

            &--third {
                &::before {
                    background: url('../img/third.svg') no-repeat;
                    background-size: 20px 20px;
                }
            }
        }
    }

    &__btn {
        margin-top: 20px;

        a {
            display: block;
            text-align: center;
            background-color: $violet;
            text-decoration: none;
            border-radius: 5px;
            padding: 8px 0;
            width: 90%;
            margin: 0 auto;
            color: $white;
            transition-property: background-color;
            transition-duration: 500ms;

            &:hover {
                background-color: lighten($violet, 20%);
            }
        }
    }
}

@media (min-width: 1024px) {
    .rating {
        margin-top: 25px;
    }
}