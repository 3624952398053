.promo {
    margin-top: 20px;

    &__slides {
        margin: 0;
    }

    &__slide {
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        padding: 20px 15px;
        min-height: 517px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__company {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            img {
                width: 20px;
                height: 20px;
            }

            span {
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                font-weight: 400;
                color: lighten($black, 20);
            }
        }

        &__title {
            min-height: 54px;

            p {
                margin: 0;
                font-size: px-to-rem(18px);
                line-height: px-to-rem(27px);
                font-weight: 500;
                color: $black;
            }
        }

        &__img {
            margin: 15px 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0 auto 10px;
            width: 95%;

            p {
                font-weight: 400;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                margin: 5px 0 5px 15px;
            }

            span {
                color: $grey;
                margin: 5px 0;
                font-weight: 400;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
            }
        }

        &__contacts {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            a {
                font-size: px-to-rem(12px);
                line-height: px-to-rem(24px);
                color: $grey;
                text-decoration: none;
                margin: 0 3px;
            }

            span {
                font-size: px-to-rem(12px);
                line-height: px-to-rem(24px);
                color: $grey;
                margin: 0 3px;
            }
        }

        &__license {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(24px);
            color: $grey;
            margin: 0 3px;
            margin-bottom: 10px;
        }

        &__btn {
            a {
                background-color: $violet;
                color: $white;
                border-radius: 5px;
                padding: 10px 0;
                text-decoration: none;
                transition-property: background-color;
                transition-duration: 250ms;
                display: block;
                text-align: center;

                &:hover {
                    background-color: lighten($violet, 20%);
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .promo {
        &__slide {
            &__info {
                min-height: 130px;
            }

            &__contacts {
                a {
                    font-size: px-to-rem(11px);
                }

                span {
                    font-size: px-to-rem(11px);
                }
            }

            &__license {
                font-size: px-to-rem(11px);
            }
        }
    }
}