.vip {
    &__item {
        background-color: $white;
        border: 2px solid $violet;
        position: relative;
        padding: 0 15px;
        margin-bottom: 20px;

        &::after {
            content: '';
            background: url('../img/vip.svg') no-repeat;
            background-size: contain;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 10px;
            right: 15px;
        }

        &--rating {
            &::after {
                content: none;
            }
        }
    }

    &__position {
        background-color: $violet;
        border-radius: 0 0 8px 8px;
        text-align: center;
        color: $white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 40px 8px;
        font-weight: 300;
    }

    &__header {
        margin: 60px 0 15px;

        a {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 500;
            text-decoration: none;
            color: $violet;
            text-transform: uppercase;
            transition-property: color;
            transition-duration: 500ms;

            &:hover {
                color: lighten($violet, 20%);
            }
        }
    }

    &__info {
        &__field {
            p {
                font-weight: 400;
                font-size: px-to-rem(16px);
                line-height: px-to-rem(18px);
                margin: 10px 0;
            }

            span {
                color: $grey;
            }
        }
    }

    &__textfield {
        p {
            font-weight: 400;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);

            &:first-of-type {
                color: $grey;
                margin: 15px 0 5px;
            }

            &:last-of-type {
                line-height: px-to-rem(21px);
                text-align: justify;
                margin-top: 5px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        &__button {
            text-decoration: none;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            color: $violet;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__contacts {
        margin: 15px 0;

        &__title {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            color: $grey;
            font-weight: 400;
            margin: 0;
            margin-bottom: 15px;

            span {
                color: $black;
            }
        }

        &--rating {
            margin-bottom: 20px;
        }
    }

    &__buttons {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
            border: 2px solid $violet;
            border-radius: 5px;
            text-decoration: none;
            text-align: center;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            color: $black;
            padding: 8px 0;
            margin-bottom: 15px;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-45%);
                left: 10%;
            }
        }

        .vip__phone {
            flex-basis: 100%;
            position: relative;

            &::after {
                background: url('../img/phone.svg') no-repeat;
                background-size: 18px 18px;
            }
        }

        .vip__telegram {
            flex-basis: 100%;
            position: relative;

            &::after {
                background: url('../img/telegram.svg') no-repeat;
                background-size: 18px 18px;
            }
        }

        .vip__whatsapp {
            flex-basis: 100%;
            position: relative;

            &::after {
                background: url('../img/whatsapp.svg') no-repeat;
                background-size: 18px 18px;
            }
        }

        .vip__email {
            flex-basis: 100%;
            position: relative;

            &::after {
                background: url('../img/email.svg') no-repeat;
                background-size: 18px 18px;
            }
        }

        .vip__lead {
            flex-basis: 100%;
            background-color: $violet;
            color: $white;
            transition-property: background-color, border-color;
            transition-duration: 500ms;
            margin-bottom: 0;

            &:hover {
                background-color: lighten($violet, 20%);
                border-color: lighten($violet, 20%);
            }
        }
    }

    &__bottom {
        margin-bottom: 15px;

        span {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(14px);
            margin: 0;
            color: $grey;
            font-weight: 400;
        }

        &--single {
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            span {
                display: inline-block;
                margin: 10px 10px 0 0;
            }

            em {
                font-style: normal;
            }

            a {
                margin-top: 10px;
                opacity: .8;
                transition-duration: 250ms;
                transition-property: opacity;

                &:hover {
                    opacity: 1;
                }
            }

            img {
                width: 16px;
                height: 16px;
                display: block;
            }
        }
    }

    &__form {
        display: none;
        width: 230px;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        padding: 25px;
        border-radius: 5px;
        z-index: 20;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        &--active {
            display: block;
        }

        &__info {
            margin-bottom: 15px;

            p {
                margin: 0;
                text-align: center;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(21px);
                font-weight: 400;
            }
        }

        &__field {
            border: 2px solid $violet;
            transition-property: border;
            transition-duration: 500ms;

            &--empty {
                border: 2px solid $drawerGrey;
            }
        }

        &__input {
            margin-bottom: 15px;
            text-align: center;

            input {
                width: 88%;
                padding: 6px 15px;
                color: $black;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                font-weight: 300;
                letter-spacing: .5px;
                border-radius: 5px;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: $drawerGrey;
                    font-size: px-to-rem(14px);
                    line-height: px-to-rem(16px);
                }
            }
        }

        &__btn {
            text-align: center;

            button {
                border: none;
                background-color: $violet;
                color: $white;
                padding: 5px 15px;
                letter-spacing: .5px;
                border-radius: 5px;
                cursor: pointer;
                transition-property: background-color;
                transition-duration: 500ms;

                &:focus {
                    outline: none;
                }
            }

            &--disabled {
                button {
                    background-color: $drawerGrey;
                    pointer-events: none;
                }
            }
        }

        &__close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}

@media (min-width: 768px) {
    .vip {
        &__buttons {

            .vip__phone,
            .vip__telegram,
            .vip__whatsapp,
            .vip__email {
                flex-basis: 48%;
            }
        }

    }
}