.addform {
    width: 100%;
    margin: 35px auto 25px;
    max-width: 620px;

    &__wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__star {
        color: $red;
        font-size: px-to-rem(18px);
    }

    &__middle,
    &__bottom,
    &__under {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__bottom-captcha {
        margin-top: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            margin-left: 15px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 300;
            padding: 5px 15px;
            border: 1px solid $drawerGrey;
            width: 30px;

            &:focus {
                outline: none;
            }
        }
    }

    &__field {
        margin-top: 15px;

        label {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            font-weight: 400;
            color: $black;
        }

        select {
            margin-top: 10px;
            width: 100%;
            padding: 7px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $black;
            border: 1px solid $drawerGrey;
            font-weight: 400;
            background-color: $white;

            &:focus {
                outline: none;
            }
        }

        textarea {
            margin-top: 10px;
            height: 250px;
            resize: none;
            width: 94%;
            padding: 10px 3% 10px 3%;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 400;

            &:focus {
                outline: none;
            }
        }

        .choices {
            margin-top: 10px;
            border: 1px solid $drawerGrey;
            width: 100%;
            background-color: $white;
        }

        .choices__inner {
            width: auto;
            background-color: transparent;
            border: none;
        }

        .choices__input {
            background-color: $white;
        }

        .choices__list--multiple {

            .choices__item {
                background-color: $violet;
                border: none;
                margin-right: 7px;
                margin-bottom: 7px;
                font-weight: 400;
                border-radius: 5px;
            }

            .choices__button {
                border-left: none;
                margin: 0;
                padding: 3px 10px;
            }
        }

        .choices__list--dropdown {
            .choices__item {
                font-size: px-to-rem(14px);
                font-weight: 400;
            }
        }
    }

    &__text {
        margin-top: 10px;
        width: 96%;
        padding: 7px 2% 7px 2%;
        border: 1px solid $drawerGrey;
        color: $black;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        font-weight: 400;

        &:focus {
            outline: none;
        }
    }

    &__input {
        border: 1px solid $violet;
        transition-property: border-color;
        transition-duration: 500ms;

        &--empty {
            border: 1px solid $drawerGrey;
        }
    }

    &__description-addinfo {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(20px);
        color: $black;
        margin: 5px 0 15px;
        font-weight: 400;

        span {
            color: $violet;
            font-weight: 500;
        }
    }

    &__addinfo {
        margin-top: 20px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(20px);
        text-align: left;
        font-weight: 400;

        a {
            color: $violet;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__btn {
        margin-top: 10px;
        text-align: center;
        width: 100%;

        button {
            background-color: $violet;
            color: $white;
            padding: 8px 20px;
            border-radius: 5px;
            border: none;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 400;
            transition-property: background-color;
            transition-duration: 500ms;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &:hover {
                background-color: lighten($violet, 20%);
            }
        }

        &--disabled {

            button {
                background-color: $drawerGrey;
                pointer-events: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .addform {
        &__wrap {
            margin: 0;
        }

        &__middle,
        &__bottom,
        &__under {
            flex-direction: row;
            flex-wrap: wrap;

            &-item {
                flex-basis: 30%;

                &--long {
                    flex-basis: 65%;
                }
            }
        }

        &__bottom-captcha {
            flex-basis: 100%;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }
}