.title {
    margin-top: 25px;
    padding: 0 15px;

    &--success {
        margin-bottom: 25px;
    }

    h1,
    h2 {
        text-align: center;
        font-size: px-to-rem(24px);
        line-height: px-to-rem(36px);
        margin: 0;
        color: $black;
    }
}