.agreement {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: $white;
    padding: 15px 25px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    h1,
    h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        font-weight: 500;
        color: $black;
        text-align: center;
    }

    p {
        font-size: px-to-rem(16px);
        line-height: px-to-rem(24px);
        color: $black;
        text-indent: 14px;
        text-align: justify;
    }
}