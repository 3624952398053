.success {
    &__info {
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        color: $black;
        font-weight: normal;
        margin: 25px auto 0;
        padding: 15px 10px;

        &--limit {
            background-color: $addInfoColor;
            border: 1px solid darken($addInfoColor, 20);
            color: lighten($black, 10);
        }
    }

    &__id {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        text-align: center;
        font-weight: 500;
        margin: 0;
    }

    &__congrats {
        text-align: center;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(24px);
        margin: 0;
        margin-top: 10px;
        font-weight: 400;
    }
}