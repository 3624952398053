.normal {
    &__item {
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        position: relative;
        padding: 20px 15px;
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }

    &__header {
        margin-bottom: 15px;

        a {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 500;
            text-decoration: none;
            color: $black;
            text-transform: uppercase;

            &:hover {
                color: lighten($violet, 20%);
            }
        }
    }

    &__info {
        &__field {
            p {
                font-weight: 300;
                font-size: px-to-rem(16px);
                line-height: px-to-rem(18px);
                margin: 10px 0;
            }

            span {
                color: $grey;
            }
        }
    }

    &__textfield {
        p {
            font-weight: 300;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);

            &:first-of-type {
                color: $grey;
                margin: 15px 0 5px;
            }

            &:last-of-type {
                line-height: px-to-rem(21px);
                text-align: justify;
                margin-top: 5px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        &__button {
            text-decoration: none;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            color: $violet;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__bottom {
        margin-top: 15px;

        span {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            margin: 0;
            color: $grey;
            font-weight: 300;

            &:last-of-type {
                margin-left: 15px;
            }
        }
    }
}