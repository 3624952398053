.comments {
    margin: 30px 0;

    &__header {
        width: 88%;
        max-width: 630px;
        margin: 0 auto 15px;

        h2 {
            font-size: px-to-rem(20px);
            line-height: px-to-rem(30px);
            margin: 0;
            font-weight: 500;
            color: $black;
        }
    }

    &__item {
        background-color: $white;
        width: 80%;
        max-width: 600px;
        margin: 0 auto 25px;
        padding: 20px 15px 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    &__avatar {
        img {
            width: 45px;
            height: 45px;
        }
    }

    &__title {
        margin-left: 15px;
    }

    &__p {
        font-weight: 400;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        margin: 0;
        color: $grey;

        &:first-of-type {
            margin-bottom: 3px;
        }

        a {
            color: lighten($violet, 20%);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__text {
        p {
            font-weight: 400;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            text-align: justify;
        }
    }

    &__input {
        text-align: center;
        margin-bottom: 10px;

        input {
            width: 80%;
            max-width: 600px;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            font-weight: 400;
            padding: 5px 15px;

            &:focus {
                outline: none;
            }
        }

        textarea {
            resize: none;
            width: 80%;
            max-width: 600px;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            font-weight: 400;
            padding: 5px 15px;
            height: 100px;

            &:focus {
                outline: none;
            }
        }

        &--blacklist {
            textarea {
                height: 200px;
            }
        }
    }

    &__textarea {
        border: 1px solid $violet;
        transition-property: border-color;
        transition-duration: 500ms;
    }

    &__field {
        border: 1px solid $violet;
        transition-property: border-color;
        transition-duration: 500ms;

        &--empty {
            border: 1px solid $drawerGrey !important;
        }
    }

    &__captcha {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 84%;
        max-width: 620px;
        margin: 0 auto;
        font-family: Arial, sans-serif;

        input {
            margin-left: 15px;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            font-weight: 400;
            border-radius: 5px;
            padding: 5px 15px;
            border: 1px solid $violet;
            width: 30px;
            transition-property: border-color;
            transition-duration: 500ms;

            &:focus {
                outline: none;
            }
        }
    }

    &__btn {
        margin-top: 15px;
        text-align: center;

        button {
            background-color: $violet;
            color: $white;
            border: none;
            border-radius: 5px;
            padding: 8px 20px;
            transition-property: background-color;
            transition-duration: 500ms;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            cursor: pointer;

            &:hover {
                background-color: lighten($violet, 20%);
            }

            &:focus {
                outline: none;
            }
        }

        &--disabled {

            button {
                background-color: $drawerGrey;
                pointer-events: none;
            }
        }

    }
}