.warning {
    max-width: 600px;
    margin: 25px auto 0;
    padding: 0 15px;

    p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        margin: 0;
        text-align: center;
        font-weight: 300;

        span {
            font-weight: 500;
        }
    }
}