.ad {
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 20px 15px;
    margin: 20px 0;
    position: relative;

    &__back {
        img {
            width: 16px;
            height: 16px;
            opacity: .8;
            transition-duration: 250ms;
            transition-property: opacity;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__header {
        margin-top: 15px;

        h1 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 500;
            color: $black;
            text-transform: uppercase;
            cursor: default;
        }

    }

    &__cities {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &__btn {
            color: $violet;
            text-decoration: none;
            background-color: $white;
            padding: 5px 15px;
            margin-right: 10px;
            margin-top: 10px;
            border-radius: 5px;
            border: 1px solid $violet;
            font-size: px-to-rem(13px);
            line-height: px-to-rem(15px);
            font-weight: 400;
            transition-property: all;
            transition-duration: 500ms;

            &:hover,
            &--active {
                border-color: lighten($violet, 20%);
                background-color: lighten($violet, 20%);
                color: $white;
            }
        }
    }
}