.messages {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transform: translateY(-110%);
    text-align: center;
    transition-duration: 500ms;
    transition-property: all;

    p {
        max-width: 400px;
        color: $white;
        letter-spacing: .5px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        padding: 25px 50px;
        text-align: center;
        margin: 0 auto;

        a {
            text-decoration: none;
            color: $white;
        }

        &::before {
            content: '';
            background: url('../img/message-icon.svg') no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
        }
    }

    &__cross {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        background: url('../img/cross-icon.svg') no-repeat;
        width: 12px;
        height: 12px;
        filter: brightness(0) invert(1);
        cursor: pointer;
    }

    &--active {
        transform: translateY(0);
    }

    &--success {
        background-color: $violet;
        box-shadow: 0 3px 0px darken($violet, 10);
    }

    &--warning {
        background-color: $red;
        box-shadow: 0 3px 0px darken($red, 10);
    }
}