.citylist {
    margin-top: 15px;

    h3 {
        text-align: center;
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        margin: 0;
        margin-bottom: 15px;
    }

    &--desktop {
        display: none;
    }

    &--mobile {
        display: block;
        margin-bottom: 25px;
    }

    &__wrap {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__btn {
        color: $violet;
        text-decoration: none;
        background-color: $white;
        padding: 5px 15px;
        margin: 5px;
        border-radius: 5px;
        border: 1px solid $violet;
        font-size: px-to-rem(13px);
        line-height: px-to-rem(15px);
        font-weight: 400;
        transition-property: all;
        transition-duration: 500ms;

        &:hover,
        &--active {
            border-color: lighten($violet, 20%);
            background-color: lighten($violet, 20%);
            color: $white;
        }
    }
}

@media (min-width: 1024px) {
    .citylist {
        &--desktop {
            display: block;

            margin-bottom: 10px;
        }

        &--mobile {
            display: none;
        }
    }
}