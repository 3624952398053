.contacts {
    background-color: $white;
    padding: 15px 20px;
    margin: 20px auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);


    &__img {
        text-align: center;
        margin-top: 10px;

        img {
            width: 200px;
            height: auto;
        }
    }

    &__text {

        p {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            font-weight: 400;
        }

        a {
            color: lighten($violet, 20%);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            padding-left: 15px;
        }

        li {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            margin: 5px 0;
            text-align: justify;
            font-weight: 400;
        }

        span {
            font-weight: 500;
            color: $red;
        }
    }

    &__p {
        margin: 15px 0;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(24px);
        font-weight: 400;
        text-align: justify;
    }

    &__link {
        margin: 5px 0;
    }
}