@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Light.woff') format('woff'),
    url('../fonts/MyriadPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Regular.woff') format('woff'),
    url('../fonts/MyriadPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Semibold.woff') format('woff'),
    url('../fonts/MyriadPro-Semibold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}