.sidelinks {
    margin-top: 25px;

    &__item {
        margin: 5px auto 20px;
        max-width: 280px;

        &:hover {
            cursor: pointer;
        }

        &--history {
            border: 1px solid $violet;
            background-color: lighten($violet, 60%);
            position: relative;

            &::after {
                content: '';
                background: url('../img/magnifier.svg') no-repeat;
                background-size: contain;
                width: 28px;
                height: 28px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 40px;
            }

            .sidelinks__link {
                color: $violet;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--blacklist {
            border: 1px solid $red;
            background-color: lighten($red, 50%);
            position: relative;

            &::after {
                content: '';
                background: url('../img/blacklist.svg') no-repeat;
                background-size: 28px 28px;
                width: 28px;
                height: 28px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 40px;
            }

            .sidelinks__link {
                color: $red;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-decoration: none;
    }

    &__text {
        width: 100%;

        p {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            margin: 10px 0;
            font-weight: 400;
            padding-left: 90px;
        }
    }
}