.archive {
    width: 86%;
    margin: 0 auto 20px;

    h2 {
        font-size: px-to-rem(24px);
        line-height: px-to-rem(32px);
        font-weight: 500;
        color: $black;
    }

    &__group {
        h3 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(24px);
            font-weight: 500;
            color: $grey;
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        li {
            margin-right: 10px;
            margin-bottom: 25px;

            a {
                color: $white;
                text-decoration: none;
                background-color: $violet;
                padding: 5px 15px;
                border-radius: 5px;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                font-weight: 300;
                transition-property: background-color;
                transition-duration: 250ms;

                &:hover {
                    background-color: lighten($violet, 20%);
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .archive {
        width: 91%;
    }
}