.categories {
    margin-top: 25px;

    &__accordion {
        padding-bottom: 15px;

        .categories__accordion__btn {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            color: $black;
            font-weight: 400;
            font-family: 'Myriad Pro', 'Arial', sans-serif;
            padding-left: 15px;

            &:focus {
                outline: none;
            }
        }

        &__link {
            display: block;
            color: $grey;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            text-decoration: none;
            padding: 5px 0 5px 25px;

            &:last-of-type {
                margin-bottom: 10px;
            }

            &:hover {
                color: $violet;
                text-decoration: underline;
            }

            &--active {
                color: $violet;
                text-decoration: underline;
            }
        }
    }
}