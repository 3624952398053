.last-ads {
    margin-top: 25px;

    h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        margin: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    &__item {
        background-color: $white;
        padding: 15px 25px;
        margin-bottom: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &__header {
        a {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            margin: 0;
            font-weight: 500;
            text-decoration: none;
            color: $black;
            transition-property: color;
            transition-duration: 500ms;

            &:hover {
                color: $violet;
            }
        }
    }

    &__field {
        p {
            font-weight: 300;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            margin: 10px 0;
        }

        span {
            color: $grey;
        }
    }
}