.leaders {
    margin: 50px 0 25px;

    p {
        font-weight: 500;
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        text-align: center;
        margin: 0 auto;
        position: relative;
        width: 120px;

        &::before {
            content: '';
            background: url('../img/star.svg') no-repeat;
            background-size: 10px 10px;
            width: 10px;
            height: 10px;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
        }

        &::after {
            content: '';
            background: url('../img/star.svg') no-repeat;
            background-size: 10px 10px;
            width: 10px;
            height: 10px;
            position: absolute;
            top: -20px;
            left: 30%;
            transform: translateX(-30%);
        }

        span {
            content: '';
            background: url('../img/star.svg') no-repeat;
            background-size: 10px 10px;
            width: 10px;
            height: 10px;
            position: absolute;
            top: -20px;
            left: 70%;
            transform: translateX(-70%);
        }
    }
}