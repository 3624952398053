.footer {
    margin-top: 20px;
    background-color: $violet;

    &__block {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    &__logo {
        margin-top: 20px;
        text-align: center;

        a {
            text-decoration: none;

            img {
                width: 120px;
                height: auto;
                opacity: .8;
                transition-property: opacity;
                transition-duration: 500ms;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &__links {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    &__link {
        margin-bottom: 10px;

        a {
            margin: 0 7px;
            color: $white;
            text-decoration: none;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            font-weight: 300;
            position: relative;

            &::after {
                content: '';
                background-image: url('../img/arrow-footer.svg');
                background-repeat: no-repeat;
                background-size: 8px 8px;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 55%;
                right: -15px;
                transform: translateY(-50%);
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &--active {
            text-decoration: underline;
        }
    }

    &__social {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        a {
            margin: 0 7px;
        }

        img {
            width: 100px;
            height: auto;
            border-radius: 5px;
            opacity: .9;
            transition-property: opacity;
            transition-duration: 500ms;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__under {
        margin-bottom: 30px;

        p {
            margin: 0;
            text-align: center;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 300;
            color: $white;

            a {
                color: $white;
                font-weight: 400;
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 576px) {
    .footer {
        &__links {
            flex-direction: row;
        }

        &__link {
            a {
                margin-right: 15px;
            }

            &:first-of-type {
                a {
                    margin-left: 0;
                }
            }
        }
    }
}