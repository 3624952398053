.text {
    background-color: $white;
    border: 2px solid $violet;
    position: relative;
    margin-bottom: 25px;

    &::after {
        content: '';
        background: url('../img/bookmark.svg') no-repeat;
        background-size: 18px 26px;
        width: 18px;
        height: 26px;
        position: absolute;
        top: -5px;
        right: 10%;
    }

    &__header {
        margin: 15px 0;

        h1 {
            font-size: px-to-rem(20px);
            line-height: px-to-rem(30px);
            font-weight: 500;
            color: $black;
            padding: 10px 20px;
            text-align: center;
            margin: 0;
            position: relative;

            &::after {
                content: '';
                width: 90%;
                height: 1px;
                position: absolute;
                background-color: $drawerGrey;
                left: 5%;
                bottom: -10px;
            }
        }
    }

    &__media {
        img {
            width: 100%;
            min-height: 250px;
            height: auto;
            object-fit: cover;
        }

        &--top {
            padding: 0 20px;
        }

        &--article {
            margin-bottom: 10px;
        }
    }

    &__content {
        padding: 20px;
        padding-bottom: 0;
        color: $black;

        p {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            color: $black;
            text-align: justify;
            font-weight: 300;

            &:first-of-type {
                margin-top: 0;
            }
        }

        a {
            font-weight: 400;
            color: $violet;
            text-decoration: underline;

            &:hover {
                color: lighten($violet, 20%);
            }
        }

        span {
            font-weight: 400;
        }

        ul {
            padding-left: 20px;
            list-style: none;

            li {
                font-size: px-to-rem(16px);
                line-height: px-to-rem(24px);
                text-align: justify;
                position: relative;
                font-weight: 300;
                margin: 10px 0;

                &::before {
                    content: '';
                    background: url('../img/star.svg') no-repeat;
                    background-size: 12px 12px;
                    width: 12px;
                    height: 12px;
                    top: 6px;
                    left: -20px;
                    position: absolute;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .text {
        &__header {
            h1 {
                font-size: px-to-rem(24px);
                line-height: px-to-rem(36px);
            }
        }

    }
}