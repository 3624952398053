.header {
  background: url(../img/bg.webp) no-repeat;
  background-position: 50% 45%;
  background-size: cover;

  &__wrap {
    position: relative;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__top {
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &__menu {
      &__btn {
        width: 20px;
        height: 16px;
        cursor: pointer;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;

        span {
          position: absolute;
          display: block;
          background-color: $white;
          height: 2px;
          border-radius: 4px;
          width: 100%;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(1) {
            top: 50%;
            transform: translateY(-50%);
          }

          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
    }

    &__link {
      display: none;
    }

    &__btn {
      background-color: rgba($violet, 0.5);
      padding: 8px 12px;
      border-radius: 5px;
      border: 2px solid rgba($white, 0.5);
      transition-property: background-color;
      transition-duration: 500ms;

      a {
        color: $white;
        text-decoration: none;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(18px);
      }

      &:hover {
        background-color: $violet;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    img {
      width: 150px;
      height: auto;
    }
  }

  &__title {
    margin-top: 30px;

    h2 {
      margin: 0;
      font-size: px-to-rem(26px);
      line-height: px-to-rem(40px);
      text-align: center;
      color: $white;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  }

  &__subtitle {
    margin-top: 10px;

    h3 {
      margin: 0;
      font-size: px-to-rem(20px);
      line-height: px-to-rem(30px);
      text-align: center;
      color: $white;
      font-weight: 300;
    }
  }

  &__menu {
    display: none;
  }

  &__drawer {
    display: none;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 15%;
    bottom: -10%;
    z-index: 20;
    transform: translateX(-100%);
    transition-duration: 500ms;
    transition-property: transform;
    overflow-y: scroll;

    &--active {
      transform: translateX(0);
    }

    &__logo {
      text-align: center;
      margin: 30px;

      img {
        width: 125px;
        height: auto;
      }
    }

    &__list,
    &__addlist {
      padding: 0 10px 15px 30px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      &__link {
        text-decoration: none;
        display: block;
        color: $violet;
        font-weight: 400;
        margin: 15px 0;

        &:hover {
          color: $red;
        }

        &--active {
          color: $red;
        }
      }
    }

    &__list {
      border-bottom: 1px solid $drawerGrey;
    }
  }
}

@media (min-width: 576px) {
  .header {
    &__title {
      h1 {
        font-size: px-to-rem(32px);
        line-height: px-to-rem(48px);
      }
    }

    &__subtitle {
      h2 {
        font-size: px-to-rem(22px);
        line-height: px-to-rem(33px);
      }
    }

    &__drawer {
      right: 50%;
    }
  }
}

@media (min-width: 768px) {
  .header {
    &__top {
      &__links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      &__link {
        display: block;
        margin-right: 25px;

        a {
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__drawer {
      right: 65%;
    }
  }
}

@media (min-width: 1024px) {
  .header {
    &__top {
      justify-content: flex-end;

      &__menu {
        display: none;
      }
    }

    &__menu {
      display: block;
      margin-top: 20px;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
      }

      &__link {
        margin: 30px 10px 0;
        display: block;
        font-weight: 500;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(18px);
        text-decoration: none;
        color: $white;
        letter-spacing: 0.5px;
        position: relative;
        font-weight: 300;

        &::after {
          content: '';
          width: 100%;
          height: 2px;
          position: absolute;
          background-color: $white;
          bottom: -10px;
          left: 0;
          transform: scale(0);
          transition-property: transform;
          transition-duration: 500ms;
        }

        &--active {
          &::after {
            transform: scale(1);
          }
        }

        &:hover::after {
          transform: scale(1);
        }
      }
    }
  }
}
